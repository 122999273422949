import React, { FC, useEffect, useState } from 'react';
import { graphql } from 'gatsby';
import { useUpdateOTLds } from '@phx-husky/use-update-ot-lds';
import { useCurrentLang } from 'hooks/useCurrentLang';
import { STRING } from 'shared/constans/strings';

import { DangerouslySetInnerHtml, Layout } from 'layout';
import ColorTopBar from 'components/ColorTopBar';

import Seo from '../../common/Seo';
import { LdsPageProps } from './models';

import './LdsPage.scss';

const LdsPage: FC<LdsPageProps> = ({
  data: {
    legalDocumentPage: { seo, urls, body, title, businessId, versionId, platform },
    allHomepage: { nodes: homepageNodes },
  },
}) => {
  const [ldsData, setLdsData] = useState<string>(body);

  const { updatedLdsContent, isCheckingLdsVersion } = useUpdateOTLds({
    baseUrl: process.env.GATSBY_LDS_BASE_URL as string,
    businessId,
    versionId,
    platform,
  });

  const {
    currentLang: [currentLangCode],
  } = useCurrentLang();

  const url = homepageNodes.find(({ lang }) => lang === currentLangCode);

  const {
    EMPTY,
    LINK_TO_PP_PAGE,
    LINK_TO_CP_PAGE,
    LINK_TO_TC_PAGE,
    LINK_TO_DSAR_PAGE,
    TEXT_NOT_LINKED_BE_NL,
    PP_WO_LINK_BE_NL,
    CP_WO_LINK_BE_NL,
    TC_WO_LINK_BE_NL,
    WRONG_LINK_BE_NL,
    CP_WO_LINK_BE_FR,
    CP_WO_LINK_BE_FR_SECOND,
    TC_WO_LINK_BE_FR,
    TC_TEXT_TO_DELETE_BE_FR,
    TC_TEXT_TO_DELETE_BE_NL,
    PP_WO_LINK_BE_FR,
    PP_WO_LINK_BE_FR_SECOND,
    WRONG_LINK_BE_FR,
    TC_TEXT_TO_REPLACE_NL,
    TC_TEXT_TO_REPLACE_NL_SECOND,
    TC_REPLACE_TEXT_NL,
    TC_REPLACE_TEXT_NL_SECOND,
  } = STRING;

  const isPrivacyPolicyPage: boolean = urls.some(({ href }) => href.includes(LINK_TO_PP_PAGE));
  const isCookiePolicyPage: boolean = urls.some(({ href }) => href.includes(LINK_TO_CP_PAGE));
  const isTermsAndConditionsPage: boolean = urls.some(({ href }) => href.includes(LINK_TO_TC_PAGE));

  useEffect(() => {
    let replacedLinksInLds: string = updatedLdsContent ?? body;

    if (!isPrivacyPolicyPage) {
      // Replace all links to privacy policy on LDS page when current adres is not privacy policy

      replacedLinksInLds = replacedLinksInLds
        .replaceAll(WRONG_LINK_BE_FR, `${url?.url}${LINK_TO_PP_PAGE}`)
        .replaceAll(
          PP_WO_LINK_BE_NL,
          `<a href="${url?.url}${LINK_TO_PP_PAGE}">${PP_WO_LINK_BE_NL}</a>`
        )
        .replaceAll(
          PP_WO_LINK_BE_FR,
          `<a href="${url?.url}${LINK_TO_PP_PAGE}">${PP_WO_LINK_BE_FR}</a>`
        )
        .replaceAll(
          PP_WO_LINK_BE_FR_SECOND,
          `<a href="${url?.url}${LINK_TO_PP_PAGE}">${PP_WO_LINK_BE_FR_SECOND}</a>`
        );
    }

    if (!isCookiePolicyPage) {
      // Replace all links to Cookie policy on LDS page when current adres is not Cookie policy

      replacedLinksInLds = replacedLinksInLds
        .replaceAll(
          CP_WO_LINK_BE_FR,
          `<a href="${url?.url}${LINK_TO_CP_PAGE}">${CP_WO_LINK_BE_FR}</a>`
        )
        .replaceAll(
          CP_WO_LINK_BE_FR_SECOND,
          `<a href="${url?.url}${LINK_TO_CP_PAGE}">${CP_WO_LINK_BE_FR_SECOND}</a>`
        )
        .replaceAll(
          TEXT_NOT_LINKED_BE_NL,
          `u, klik dan <a target="_blank" href="${LINK_TO_DSAR_PAGE}">hier </a>`
        )
        .replaceAll(
          CP_WO_LINK_BE_NL,
          `<a href="${url?.url}${LINK_TO_CP_PAGE}">${CP_WO_LINK_BE_NL}</a>`
        );
    }

    if (!isTermsAndConditionsPage) {
      // Replace all links to Terms and Conditions on LDS page when current adres is not Terms and Conditions

      replacedLinksInLds = replacedLinksInLds
        .replaceAll(
          TC_WO_LINK_BE_NL,
          `<a href="${url?.url}${LINK_TO_TC_PAGE}">${TC_WO_LINK_BE_NL}</a>`
        )
        .replaceAll(
          TC_WO_LINK_BE_FR,
          `<a href="${url?.url}${LINK_TO_TC_PAGE}">${TC_WO_LINK_BE_FR}</a>`
        );
    }

    if (isTermsAndConditionsPage) {
      // Replace all links to Terms and Conditions on LDS page when current adres Terms and Conditions

      replacedLinksInLds = replacedLinksInLds
        .replaceAll(TC_TEXT_TO_DELETE_BE_NL, EMPTY)
        .replaceAll(WRONG_LINK_BE_NL, EMPTY)
        .replaceAll(TC_TEXT_TO_DELETE_BE_FR, EMPTY)
        .replace(TC_TEXT_TO_REPLACE_NL, TC_REPLACE_TEXT_NL)
        .replace(TC_TEXT_TO_REPLACE_NL_SECOND, TC_REPLACE_TEXT_NL_SECOND);
    }

    setLdsData(replacedLinksInLds);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [url, updatedLdsContent]);

  return (
    <Layout>
      <Seo {...{ urls, seo }} />
      <ColorTopBar />
      <h1 className="lds__title">{title}</h1>
      {!isCheckingLdsVersion && <DangerouslySetInnerHtml html={ldsData} className="lds__content" />}
    </Layout>
  );
};

export const query = graphql`
  query ($lang: String, $path: [String]) {
    legalDocumentPage(lang: { eq: $lang }, url: { in: $path }) {
      seo {
        ...SEOStructureFragment
      }
      urls {
        ...UrlsFragment
      }
      title
      body
      businessId: businessID
      versionId
      platform
    }
    allHomepage {
      nodes {
        url
        lang
      }
    }
  }
`;

export default LdsPage;
