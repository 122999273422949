export const STRING = {
  EMPTY: ``,
  LINK_TO_CP_PAGE: 'cookie-policy/',
  LINK_TO_PP_PAGE: 'privacy-policy/',
  LINK_TO_TC_PAGE: 'terms-and-conditions/',
  LINK_TO_DSAR_PAGE: 'http://www.dsar-rb.com/',
  TEXT_NOT_LINKED_BE_NL: 'u, klik dan&nbsp;hier&nbsp;',
  PP_WO_LINK_BE_NL: 'Privacyverklaring',
  CP_WO_LINK_BE_NL: 'Cookiebeleid',
  TC_WO_LINK_BE_NL: 'Gebruiksvoorwaarden',
  PP_WO_LINK_BE_FR: 'Avis de confidentialité',
  PP_WO_LINK_BE_FR_SECOND: 'Déclaration de confidentialité',
  CP_WO_LINK_BE_FR: 'politique relative aux cookies',
  CP_WO_LINK_BE_FR_SECOND: 'Politique en matière de cookies',
  TC_WO_LINK_BE_FR: `conditions d'utilisation`,
  TC_TEXT_TO_DELETE_BE_FR: `disponible à&nbsp;l’adresse&nbsp;suivante`,
  TC_TEXT_TO_DELETE_BE_NL: ` beschikbaar&nbsp;op`,
  TC_TEXT_TO_REPLACE_NL: `&nbsp;op&nbsp;en ons`,
  TC_TEXT_TO_REPLACE_NL_SECOND: `&nbsp;op&nbsp;te lezen`,
  TC_REPLACE_TEXT_NL: `&nbsp;en ons`,
  TC_REPLACE_TEXT_NL_SECOND: `&nbsp;te lezen`,
  WRONG_LINK_BE_NL: ` beschikbaar&nbsp;<a href="http://www.durex.be/nl/cookies-beleid/">op</a>`,
  WRONG_LINK_BE_FR: 'http://www.durex.be/fr/politique-de-confidentialite/',
};
